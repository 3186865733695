<template>
  <div>
    <canvas class="background"/>
    <div class="container">
      <div style="width: 100%;text-align: center;">
        <img style="width:100px; height:100px; border-radius:50%;border: gray 1px solid;"
             src="//q.qlogo.cn/headimg_dl?dst_uin=2470846721&spec=640&img_type=jpg" alt="background"/>
      </div>
      <h1>您好, 这里是阿空的主页！</h1>
      <ul>
        <li>本页面不做其余展示</li>
        <li>您可以查看阿空的音乐</li>
        <li><a href="https://music.akongwl.top/">AkongMusic</a></li>
      </ul>
    </div>
    <div style="text-align: center;position: fixed;bottom: 30px;width: 100%">
      网站已接受备案：<a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2021018958号-1</a>
    </div>
  </div>
</template>

<script>
import Particles from 'particlesjs';

export default {
  name: "IndexView",
  mounted() {
    Particles.init({
      selector: '.background',
      color: '#a5a2a2',
      maxParticles: 130,
      speed: 0.3,
      connectParticles: 1
    });
  }
}
</script>

<style lang="less" scoped>
.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

.container {
  width: 60%;
  margin: 10% auto 0;
  background-color: #f0f0f0;
  padding: 2% 5%;
  border-radius: 10px;
  opacity: 80%;
}

ul {
  padding-left: 20px;
}

ul li {
  line-height: 2.3
}

a {
  color: #20a53a
}

.back_img{
  background: url('//pic4.zhimg.com/v2-80d2c0f994d8bd1f938e37f7551b3a6b_1440w') no-repeat;
  background-size: 100%;
}
</style>
